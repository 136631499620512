import React, { FC, useRef } from 'react'
import { Box, Container, Typography, Skeleton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import { AccountHistory } from '@talentinc/gatsby-theme-ecom/types/accountHistory'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import useSiteMetadata from '@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata'
import usePageMeasurements from '@talentinc/gatsby-theme-ecom/hooks/usePageMeasurements'
import useUser from '@talentinc/gatsby-theme-ecom/hooks/useUser'
import formatDate from '@talentinc/gatsby-theme-ecom/utils/date'
import resumeImg from '@talentinc/gatsby-theme-ecom/images/resume-half.svg'
import resumeRedImgFr from '@talentinc/gatsby-theme-ecom/images/resume-checks-red-fr.svg'
import resumeRedImg from '@talentinc/gatsby-theme-ecom/images/resume-checks-red.svg'
import upsellImg from '@talentinc/gatsby-theme-ecom/images/upsell.svg'
import upsellTopInterviewImg from '@talentinc/gatsby-theme-ecom/images/upsell-topinterview.svg'
import Sidebar from './Sidebar'
import CTABanner from './CTABanner'
import HeroBanner from './HeroBanner'
import ResumeReview from './ResumeReview'
import {
  useCritiqueStateQuery,
  useSessionQuery,
} from '../Providers/UserSession'

type TemplateProps = {
  accountHistory: AccountHistory
}

const AccountHistoryTemplate: FC<TemplateProps> = ({ accountHistory }) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const { classes } = useStyles()
  const {
    header: [, header],
    footer: [, footer],
  } = usePageMeasurements()
  const { t } = useTranslation()
  const {
    display_name: brandName,
    default_resume_product: defaultResumeProduct = '',
    isZipJob,
  } = useBrand()
  const { locale } = useSiteMetadata()
  const { data: sessionData } = useSessionQuery()
  const { data: critiqueState } = useCritiqueStateQuery()
  const { lead_auth_token: leadAuthToken } = useUser()

  const defaultResumeProductLowercase = defaultResumeProduct.toLowerCase()

  const {
    resume_status,
    critique_emailed_at,
    critique_viewed_at,
    critique_expired_at,
    purchased_resume_at,
    first_name,
    critique_persona,
  } = critiqueState ?? {}

  const resumeIsNearlyPerfect =
    (resume_status === 5 &&
      critique_expired_at &&
      new Date(critique_expired_at).getTime() > new Date().getTime()) ||
    (critique_emailed_at &&
      purchased_resume_at &&
      new Date(purchased_resume_at).getTime() >
        new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000).getTime())

  const reviewIsExpired =
    resume_status === 6 ||
    (critique_expired_at &&
      new Date(critique_expired_at).getTime() < new Date().getTime())

  const resumeIsTroubled =
    resume_status === 3 &&
    critique_expired_at &&
    new Date(critique_expired_at).getTime() > new Date().getTime()

  const critiqueViewed =
    critique_viewed_at &&
    critique_expired_at &&
    new Date(critique_expired_at).getTime() > new Date().getTime()

  const reviewIsReady =
    critique_emailed_at &&
    critique_expired_at &&
    new Date(critique_expired_at).getTime() > new Date().getTime() &&
    !critique_viewed_at

  const resumeIsBeingReviewed =
    resume_status && resume_status >= 1 && !critique_emailed_at

  const noResume =
    !resume_status &&
    !critique_emailed_at &&
    !critique_viewed_at &&
    !critique_expired_at

  // This condition is to show only single critique state at a time,
  // and this in the priority order of states
  const critiqueCurrentState =
    !!resumeIsNearlyPerfect ||
    !!reviewIsExpired ||
    !!resumeIsTroubled ||
    !!critiqueViewed ||
    !!reviewIsReady ||
    !!resumeIsBeingReviewed ||
    !!noResume

  const critiqueStateView = () => {
    switch (critiqueCurrentState) {
      case !!resumeIsNearlyPerfect:
        return (
          <ResumeReview
            title={t(
              'accountHistory.resumeReview.yourResumeIsNearlyPerfect.title',
              { defaultResumeProduct }
            )}
            primaryContent={t(
              'accountHistory.resumeReview.yourResumeIsNearlyPerfect.primaryText',
              { defaultResumeProduct }
            )}
            secondaryContent={
              <Typography className={classes.resumeReviewDescription}>
                <strong>
                  {t(
                    'accountHistory.resumeReview.yourResumeIsNearlyPerfect.secondaryText'
                  )}
                </strong>{' '}
                {t(
                  'accountHistory.resumeReview.yourResumeIsNearlyPerfect.secondaryText2',
                  { defaultResumeProduct }
                )}
              </Typography>
            }
            primaryButtonText={t(
              'accountHistory.resumeReview.yourResumeIsNearlyPerfect.primaryCta'
            )}
            primaryButtonHref={t(
              'accountHistory.slugs.linkedinProfileServices'
            )}
          />
        )
      case !!reviewIsExpired:
        return (
          <ResumeReview
            title={t('accountHistory.resumeReview.yourReviewHasExpired.title')}
            primaryContent={t(
              'accountHistory.resumeReview.yourReviewHasExpired.primaryText',
              { brandName, defaultResumeProduct }
            )}
            primaryButtonText={t(
              'accountHistory.resumeReview.yourReviewHasExpired.primaryCta',
              { defaultResumeProduct }
            )}
            secondaryButtonText={t(
              'accountHistory.resumeReview.yourReviewHasExpired.secondaryCta',
              { defaultResumeProduct }
            )}
            primaryButtonHref={t('accountHistory.slugs.resumeReview', {
              defaultResumeProductLowercase,
            })}
            secondaryButtonHref={t('accountHistory.slugs.resumeWriting', {
              defaultResumeProductLowercase,
            })}
            containerClass={classes.resumeReviewContainer}
            imgSrc={locale === 'fr' ? resumeRedImgFr : resumeRedImg}
          />
        )
      case !!resumeIsTroubled:
        return (
          <ResumeReview
            title={t(
              'accountHistory.resumeReview.yourResumeCouldNotBeRead.title',
              { defaultResumeProduct }
            )}
            primaryContent={t(
              'accountHistory.resumeReview.yourResumeCouldNotBeRead.primaryText',
              { brandName, defaultResumeProduct }
            )}
            primaryButtonText={t(
              'accountHistory.resumeReview.yourResumeCouldNotBeRead.primaryCta'
            )}
            primaryButtonHref={t('accountHistory.slugs.resumeWriting', {
              defaultResumeProductLowercase,
            })}
            containerClass={classes.resumeReviewContainer}
            imgSrc={locale === 'fr' ? resumeRedImgFr : resumeRedImg}
          />
        )
      case !!critiqueViewed:
        // Recommended
        return <HeroBanner />
      case !!reviewIsReady:
        return (
          <ResumeReview
            title={t(
              'accountHistory.resumeReview.yourResumeReviewIsReady.title',
              { defaultResumeProduct }
            )}
            primaryContent={t(
              'accountHistory.resumeReview.yourResumeReviewIsReady.primaryText',
              { defaultResumeProduct, writer: critique_persona }
            )}
            primaryButtonText={t(
              'accountHistory.resumeReview.yourResumeReviewIsReady.primaryCta'
            )}
            primaryButtonHref={`${t('accountHistory.slugs.resumeCritiqueView', {
              defaultResumeProductLowercase,
            })}${leadAuthToken ? `?at=${leadAuthToken}` : ''}`}
          />
        )
      case !!resumeIsBeingReviewed:
        return (
          <ResumeReview
            title={t(
              'accountHistory.resumeReview.yourResumeIsBeingReviewed.title',
              { defaultResumeProduct }
            )}
            primaryContent={t(
              'accountHistory.resumeReview.yourResumeIsBeingReviewed.primaryText',
              { defaultResumeProduct }
            )}
            secondaryContent={t(
              'accountHistory.resumeReview.yourResumeIsBeingReviewed.secondaryText',
              { defaultResumeProduct }
            )}
            containerClass={classes.resumeReviewContainer}
          />
        )
      case !!noResume:
        return (
          <ResumeReview
            title={t('accountHistory.resumeReview.getFreeResumeReview.title')}
            primaryContent={t(
              'accountHistory.resumeReview.getFreeResumeReview.primaryText',
              { defaultResumeProduct }
            )}
            primaryButtonText={t(
              'accountHistory.resumeReview.getFreeResumeReview.primaryCta',
              { defaultResumeProduct }
            )}
            primaryButtonHref={t('accountHistory.slugs.resumeReview', {
              defaultResumeProductLowercase,
            })}
          />
        )

      default:
        return (
          <ResumeReview
            title={t('accountHistory.resumeReview.getFreeResumeReview.title')}
            primaryContent={t(
              'accountHistory.resumeReview.getFreeResumeReview.primaryText',
              { defaultResumeProduct }
            )}
            primaryButtonText={t(
              'accountHistory.resumeReview.getFreeResumeReview.primaryCta',
              { defaultResumeProduct }
            )}
            primaryButtonHref={t('accountHistory.slugs.resumeReview', {
              defaultResumeProductLowercase,
            })}
          />
        )
    }
  }

  const shouldShowRevisitResume =
    resume_status &&
    resume_status > 1 &&
    critique_expired_at &&
    new Date(critique_expired_at).getTime() > new Date().getTime() &&
    (critique_emailed_at || resumeIsNearlyPerfect || resumeIsTroubled)

  return (
    <Container
      ref={mainRef}
      className={classes.root}
      disableGutters
      component="main"
      style={{
        minHeight: `calc(100vh - ${header.height + footer.height}px)`,
      }}
    >
      <div className={classes.content}>
        <Sidebar
          email={sessionData?.email ?? ''}
          name={first_name}
          shouldShowRevisitResume={!!shouldShowRevisitResume}
          critiqueViewed={!!critiqueViewed}
          resumeUploaded={!noResume}
        />
        {!critiqueState ? (
          <Box className={classes.mainContent}>
            <Skeleton
              variant="rectangular"
              className={classes.resumeReviewLoader}
            />
            <Skeleton
              variant="rectangular"
              className={classes.ctaBannerLoader}
            />
          </Box>
        ) : (
          <Box className={classes.mainContent}>
            {critiqueStateView()}

            {shouldShowRevisitResume && critiqueViewed && (
              <CTABanner
                title={t(
                  'accountHistory.ctaBanner.readYourResumeReview.title',
                  {
                    defaultResumeProduct,
                  }
                )}
                description={t(
                  'accountHistory.ctaBanner.readYourResumeReview.description',
                  {
                    writer: critique_persona,
                    date: critique_emailed_at
                      ? formatDate(
                          critique_emailed_at,
                          t('accountHistory.dateFormat'),
                          { locale: locale === 'fr' ? fr : en }
                        )
                      : '',
                  }
                )}
                imageSrc={resumeImg}
                navigateTo={`${t('accountHistory.slugs.resumeCritiqueView', {
                  defaultResumeProductLowercase,
                })}${leadAuthToken ? `?at=${leadAuthToken}` : ''}`}
              />
            )}

            {locale === 'fr' || isZipJob ? (
              <CTABanner
                title={t('accountHistory.ctaBanner.improveLinkedIn.title')}
                description={t(
                  'accountHistory.ctaBanner.improveLinkedIn.description'
                )}
                imageSrc={upsellImg}
                imageContainerClass={classes.ctaBannerImage}
                navigateTo={t('accountHistory.slugs.linkedinProfileServices')}
              />
            ) : (
              <CTABanner
                title={t('accountHistory.ctaBanner.topInterview.title')}
                description={t(
                  'accountHistory.ctaBanner.topInterview.description'
                )}
                imageSrc={upsellTopInterviewImg}
                imageContainerClass={classes.ctaBannerImage}
                navigateTo="https://www.topinterview.com"
                target="_blank"
              />
            )}
          </Box>
        )}
      </div>
    </Container>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    gap: '4rem',
    padding: '2rem 5rem',
    margin: 0,
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      padding: '2rem 3.5rem',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: '1.5rem',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      gap: '3.5rem',
    },
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  ctaBannerImage: {
    padding: '2rem',
  },
  resumeReviewContainer: {
    backgroundColor: theme.colors.neutral[5],
  },
  resumeReviewDescription: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  resumeReviewLoader: {
    borderRadius: '12px',
    height: '376px',
    width: '100%',
  },
  ctaBannerLoader: {
    borderRadius: '12px',
    height: '136px',
    width: '100%',
  },
}))

export default AccountHistoryTemplate
